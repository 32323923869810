import { useCallback, useReducer } from "react";
import { RequestStatus, SlaModel } from "types/common.types";
import * as agreementsApi from "api/agreements.api";

interface State {
  request: RequestStatus;
  data: SlaModel | null;
}

type ActionType = "FETCH" | "SUCCESS" | "FAILURE";

type Action = { type: ActionType; payload?: any };

const initState: State = {
  data: null,
  request: RequestStatus.UNCALLED,
};

interface UseSlaFetch {
  fetch: (slaCode: SlaModel["slaCode"]) => {};
  data: State["data"];
  isPending: boolean;
}

const slaFetchReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "FETCH": {
      return {
        ...state,
        request: RequestStatus.PENDING,
      };
    }
    case "SUCCESS": {
      return {
        ...state,
        request: RequestStatus.SUCCESS,
        data: action.payload,
      };
    }
    case "FAILURE": {
      return {
        ...state,
        request: RequestStatus.FAILURE,
      };
    }
    default:
      throw new Error();
  }
};

const useSlaFetch = (): UseSlaFetch => {
  const [{ data, request }, dispatch] = useReducer(slaFetchReducer, initState);
  const isPending = request === RequestStatus.PENDING;

  const fetch = useCallback(
    async (slaCode: SlaModel["slaCode"]) => {
      if (request === RequestStatus.UNCALLED) {
        dispatch({ type: "FETCH" });
        try {
          const { data } = await agreementsApi.getCustomerSla(slaCode);
          dispatch({ type: "SUCCESS", payload: data });
        } catch (error) {
          dispatch({ type: "FAILURE" });
        }
      }
    },
    [dispatch, request]
  );

  return { data, isPending, fetch };
};

export default useSlaFetch;
