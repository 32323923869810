import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import { useLocation, matchPath, useHistory } from "react-router-dom";
import { Breadcrumbs } from "@skyportal/ui-kit";
import { makeTitleFromBreadcrumbs } from "./utils";
import { getBreadcrumbsConfig } from "./config";

const ModuleBreadcrumbs = () => {
  const { pathname } = useLocation();
  const history = useHistory();
  const configItem = getBreadcrumbsConfig().find((item) => matchPath(pathname, item.match));

  if (!configItem) {
    return null;
  }

  const documentTitle = makeTitleFromBreadcrumbs(configItem);

  return (
    <>
      <Helmet>
        <title>{documentTitle}</title>
      </Helmet>
      <Breadcrumbs config={configItem} onBreadcrumbClick={history.push} />
    </>
  );
};

export default ModuleBreadcrumbs;
