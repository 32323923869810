import React from "react";
import { useTranslation } from "react-i18next";
import { WithOffering } from "types/common.types";
import { Button, Grid, Icon, Text } from "@skyportal/ui-kit";
import { offeringTypeToDashboardUrl, offeringTypeToIcon, offeringTypeToLocaleLabel } from "utils/mappers";
import { formatDate } from "utils/time";
import { isSlaInvalid } from "utils/sla";
import SlaTooltip from "../SlaTooltip";
import OfferingTableRow from "../OfferingTableRow";
import styles from "./styles.module.css";

interface OfferingCardProps extends WithOffering {}

export const OfferingCard: React.FC<OfferingCardProps> = ({ offering }) => {
  const { t } = useTranslation("agreements");
  const linkToServiceDashboard = offeringTypeToDashboardUrl(offering.type);

  return (
    <Grid item xs={4} className={styles.wrapper} data-testid="offeringCard">
      <div className={styles.root}>
        <Grid container spacing={2}>
          <Grid item container wrap="nowrap" alignItems="center" xs={12} spacing={2}>
            <Grid item>
              <Icon icon={offeringTypeToIcon(offering.type)} height={32} />
            </Grid>
            <Grid item>
              <div>
                <Text strong size="large">
                  {offering.name}
                </Text>
              </div>
              <Text>{t(offeringTypeToLocaleLabel(offering.type))}</Text>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <table width="100%">
              <colgroup>
                <col width="20px" />
                <col width="100px" />
                <col />
              </colgroup>
              <tbody>
                {offering.agreementStatus && (
                  <OfferingTableRow label={t("Status")}>{offering.agreementStatus}</OfferingTableRow>
                )}
                {offering.startDate && (
                  <OfferingTableRow icon="clock" label={t("Start")}>
                    {formatDate(offering.startDate)}
                  </OfferingTableRow>
                )}
                {offering.endDate && (
                  <OfferingTableRow label={t("Expires")}>{formatDate(offering.endDate)}</OfferingTableRow>
                )}
                {!isSlaInvalid(offering.slaCode) && (
                  <OfferingTableRow icon="document-check" label={t("SLA")}>
                    {<SlaTooltip offering={offering} />}
                  </OfferingTableRow>
                )}
              </tbody>
            </table>
          </Grid>
          {linkToServiceDashboard && (
            <Grid container item justify="flex-end">
              <Grid item>
                <a href={linkToServiceDashboard} target="_blank" rel="noreferrer">
                  <Button>{t("Dashboard")}</Button>
                </a>
              </Grid>
            </Grid>
          )}
        </Grid>
      </div>
    </Grid>
  );
};

export default OfferingCard;
