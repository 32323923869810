// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".agreements_styles_root__Ozrc8 {\n  padding: var(--padding-medium);\n  border: 1px solid var(--color-grey-03);\n  border-radius: 2px;\n  transition: background-color var(--transition-duration);\n}\n\n.agreements_styles_root__Ozrc8:hover {\n  background-color: var(--color-bg-hover);\n}\n\n.agreements_styles_wrapper__Sm-BY {\n  min-width: 300px;\n}\n", "",{"version":3,"sources":["webpack://./src/containers/OfferingList/components/OfferingCard/styles.module.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAC9B,sCAAsC;EACtC,kBAAkB;EAClB,uDAAuD;AACzD;;AAEA;EACE,uCAAuC;AACzC;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".root {\n  padding: var(--padding-medium);\n  border: 1px solid var(--color-grey-03);\n  border-radius: 2px;\n  transition: background-color var(--transition-duration);\n}\n\n.root:hover {\n  background-color: var(--color-bg-hover);\n}\n\n.wrapper {\n  min-width: 300px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "agreements_styles_root__Ozrc8",
	"wrapper": "agreements_styles_wrapper__Sm-BY"
};
export default ___CSS_LOADER_EXPORT___;
