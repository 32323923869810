import React from "react";
import { Card, Grid } from "@skyportal/ui-kit";
import useCustomerOfferings from "hooks/useCustomerOfferings";
import OfferingCard from "./components/OfferingCard";
import NotFoundLabel from "./components/NotFoundLabel";

const OfferingList: React.FC = () => {
  const { isFetching, offerings } = useCustomerOfferings();

  return (
    <Grid item xs={12} data-testid="offeringListContainer">
      <Card padding="medium" title="Offerings" loading={isFetching} style={{ minHeight: "150px" }}>
        <NotFoundLabel show={!isFetching && !offerings.length} />
        <Grid container spacing={2}>
          {offerings.map((item, index) => (
            <OfferingCard offering={item} key={index} />
          ))}
        </Grid>
      </Card>
    </Grid>
  );
};

export default OfferingList;
