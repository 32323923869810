import { RequestStatus } from "types/common.types";
import { AgreementActions, AgreementsStateSlice } from "./agreements.types";
import { SetUserProfileAction } from "../app/app.types";

export const initState: AgreementsStateSlice = {
  customerErpId: "",
  customerList: [],
  searchCustomer: "",
  customersPerPage: 6,
  customerSortOrder: -1,
  customersSortField: "name",
  customersPage: 1,
  customerListRequest: RequestStatus.UNCALLED,
  list: [],
  listRequest: RequestStatus.UNCALLED,
  offeringList: [],
  offeringListRequest: RequestStatus.UNCALLED,
};

const agreementsReducer = (
  state: AgreementsStateSlice = initState,
  action: AgreementActions | SetUserProfileAction
): AgreementsStateSlice => {
  switch (action.type) {
    case "AGREEMENTS/LIST_REQUEST": {
      return {
        ...state,
        listRequest: RequestStatus.PENDING,
      };
    }
    case "AGREEMENTS/LIST_REQUEST_SUCCESS": {
      return {
        ...state,
        list: action.payload,
        listRequest: RequestStatus.SUCCESS,
      };
    }
    case "AGREEMENTS/LIST_REQUEST_FAILURE": {
      return {
        ...state,
        list: [],
        listRequest: RequestStatus.FAILURE,
      };
    }
    case "APP/SET_USER_PROFILE": {
      return {
        ...state,
        customerErpId: action.payload.customerErpId || "",
      };
    }
    case "AGREEMENTS/SET_CUSTOMER_ERP_ID": {
      return {
        ...state,
        customerErpId: action.payload,
      };
    }
    case "AGREEMENTS/CUSTOMER_LIST_REQUEST": {
      return {
        ...state,
        customerListRequest: RequestStatus.PENDING,
      };
    }
    case "AGREEMENTS/CUSTOMER_LIST_REQUEST_FAILURE": {
      return {
        ...state,
        customerListRequest: RequestStatus.FAILURE,
      };
    }
    case "AGREEMENTS/CUSTOMER_LIST_REQUEST_SUCCESS": {
      return {
        ...state,
        customerListRequest: RequestStatus.SUCCESS,
        customerList: action.payload,
        customerErpId: "",
      };
    }
    case "AGREEMENTS/OFFERING_LIST_REQUEST": {
      return {
        ...state,
        offeringListRequest: RequestStatus.PENDING,
      };
    }
    case "AGREEMENTS/OFFERING_LIST_REQUEST_FAILURE": {
      return {
        ...state,
        offeringList: [],
        offeringListRequest: RequestStatus.FAILURE,
      };
    }
    case "AGREEMENTS/OFFERING_LIST_REQUEST_SUCCESS": {
      return {
        ...state,
        offeringListRequest: RequestStatus.SUCCESS,
        offeringList: action.payload,
      };
    }
    case "AGREEMENTS/SET_CUSTOMER_SORT_FIELD": {
      return {
        ...state,
        customersSortField: action.payload,
        customerSortOrder: state.customersSortField === action.payload && state.customerSortOrder === 1 ? -1 : 1,
      };
    }
    case "AGREEMENTS/SET_CUSTOMER_PAGE": {
      return {
        ...state,
        customersPage: action.payload,
      };
    }
    case "APP/SET_ACTIVE_MODAL": {
      return {
        ...state,
        searchCustomer: "",
      };
    }
    case "AGREEMENTS/SET_CUSTOMER_SEARCH_TEXT": {
      return {
        ...state,
        customersPage: 1,
        searchCustomer: action.payload,
      };
    }
    default:
      return state;
  }
};

export default agreementsReducer;
