import React from "react";
import { Grid } from "@skyportal/ui-kit";
import PageLayout from "layouts/PageLayout";
import PageHeader from "components/PageHeader";
import AgreementList from "containers/AgreementList";
import OfferingList from "containers/OfferingList";
import AdminFilterBar from "containers/AdminFilterBar";
import { useTranslation } from "react-i18next";

export const RootPage: React.FC = () => {
  const { t } = useTranslation("agreements");

  return (
    <PageLayout>
      <PageHeader title={t("Agreements")} />
      <Grid container spacing={2}>
        <AdminFilterBar />
        <AgreementList />
        <OfferingList />
      </Grid>
    </PageLayout>
  );
};

export default RootPage;
