import axios from "axios";
import config from "config";
import createAuthClient from "@skyportal/auth-web-client";

export const authClient = createAuthClient({
  authWebURL: config.authWebUrl,
  authApiURL: config.authApiUrl,
});

export const agreementsClient = axios.create({
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
  baseURL: config.apiUrl,
});
