import { agreementsClient } from "./clients";
import { OfferingModel, AgreementModel, SlaModel, SLAType, CustomerListItemModel } from "../types/common.types";

interface CustomerAgreementsResponse {
  customerErpId: string;
  agreements: AgreementModel[];
}

export const getCustomerAgreements = (customerErpId: string) =>
  agreementsClient.request<CustomerAgreementsResponse>({
    method: "GET",
    url: `/v1/customer/${customerErpId}/agreements`,
  });

interface CustomerOfferingsResponse {
  customerErpId: string;
  offerings: OfferingModel[];
}

export const getCustomerOfferings = (customerErpId: string) =>
  agreementsClient.request<CustomerOfferingsResponse>({
    method: "GET",
    url: `/v1/customer/${customerErpId}/offerings`,
  });

interface CustomerSlaResponse extends SlaModel {}

export const getCustomerSla = (slaCode: SLAType) =>
  agreementsClient.request<CustomerSlaResponse>({
    method: "GET",
    url: `/v1/sla/${slaCode}`,
  });

export const getCustomerList = () =>
  agreementsClient.request<CustomerListItemModel[]>({
    method: "GET",
    url: "/v1/customer/all",
  });
