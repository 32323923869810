import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useThunkDispatch } from "store";
import { agreementsSelectors, agreementsThunks } from "store/agreements";
import { AgreementModel, RequestStatus } from "types/common.types";

export interface UseCustomerAgreements {
  agreements: AgreementModel[];
  isFetching: boolean;
}

const useCustomerAgreements = () => {
  const dispatch = useThunkDispatch();
  const request = useSelector(agreementsSelectors.getListRequest);
  const agreements = useSelector(agreementsSelectors.getList);
  const customerErpId = useSelector(agreementsSelectors.getCustomerErpId);

  useEffect(() => {
    if (customerErpId) {
      dispatch(agreementsThunks.fetchCustomerAgreements(customerErpId));
    }
  }, [dispatch, customerErpId]);

  return { agreements, isFetching: RequestStatus.PENDING === request };
};

export default useCustomerAgreements;
