import React from "react";
import { Text } from "@skyportal/ui-kit";
import { useTranslation } from "react-i18next";
import NotFoundPlaceholderLayout from "layouts/NotFoundPlaceholderLayout";

export interface NotFoundLabelProps {
  show?: boolean;
}

const NotFoundLabel: React.FC<NotFoundLabelProps> = ({ show = false }) => {
  const { t } = useTranslation("agreements");

  if (!show) {
    return null;
  }

  return (
    <NotFoundPlaceholderLayout>
      <Text>{t("No active Offerings found")}</Text>
    </NotFoundPlaceholderLayout>
  );
};

export default NotFoundLabel;
