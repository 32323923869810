import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Text } from "@skyportal/ui-kit";
import { agreementsSelectors } from "store/agreements";

const TotalSearchResult = () => {
  const { t } = useTranslation("agreements");
  const { total } = useSelector(agreementsSelectors.getPaginatedCustomerList);

  return (
    <Text>
      {t("Total")}: {total}
    </Text>
  );
};

export default React.memo(TotalSearchResult);
