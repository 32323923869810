import {
  AgreementListRequestAC,
  AgreementListRequestFailureAC,
  AgreementListRequestSuccessAC,
  CUSTOMER_LIST_REQUEST,
  CUSTOMER_LIST_REQUEST_FAILURE,
  CUSTOMER_LIST_REQUEST_SUCCESS,
  CustomerListRequestAC,
  CustomerListRequestFailureAC,
  CustomerListRequestSuccessAC,
  LIST_REQUEST,
  LIST_REQUEST_FAILURE,
  LIST_REQUEST_SUCCESS,
  OFFERING_LIST_REQUEST,
  OFFERING_LIST_REQUEST_FAILURE,
  OFFERING_LIST_REQUEST_SUCCESS,
  OfferingListRequestAC,
  OfferingListRequestFailureAC,
  OfferingListRequestSuccessAC,
  SET_CUSTOMER_ERP_ID,
  SET_CUSTOMER_PAGE,
  SET_CUSTOMER_SEARCH_TEXT,
  SET_CUSTOMER_SORT_FIELD,
  SetCustomerErpIdAC,
  SetCustomerPageAC,
  SetCustomerSearchTextAC,
  SetCustomerSortFieldAC,
} from "./agreements.types";

export const agreementListRequest: AgreementListRequestAC = () => ({
  type: LIST_REQUEST,
});

export const agreementListRequestSuccess: AgreementListRequestSuccessAC = (payload) => ({
  type: LIST_REQUEST_SUCCESS,
  payload,
});

export const agreementListRequestFailure: AgreementListRequestFailureAC = () => ({
  type: LIST_REQUEST_FAILURE,
});

export const setCustomerErpIdAC: SetCustomerErpIdAC = (payload) => ({
  type: SET_CUSTOMER_ERP_ID,
  payload,
});

export const customerListRequestAC: CustomerListRequestAC = () => ({
  type: CUSTOMER_LIST_REQUEST,
});

export const customerListRequestSuccessAC: CustomerListRequestSuccessAC = (payload) => ({
  type: CUSTOMER_LIST_REQUEST_SUCCESS,
  payload,
});

export const customerListRequestFailureAC: CustomerListRequestFailureAC = () => ({
  type: CUSTOMER_LIST_REQUEST_FAILURE,
});

export const offeringListRequestAC: OfferingListRequestAC = () => ({
  type: OFFERING_LIST_REQUEST,
});

export const offeringListRequestSuccessAC: OfferingListRequestSuccessAC = (payload) => ({
  type: OFFERING_LIST_REQUEST_SUCCESS,
  payload,
});

export const offeringListRequestFailureAC: OfferingListRequestFailureAC = () => ({
  type: OFFERING_LIST_REQUEST_FAILURE,
});

export const setCustomerSortFieldAC: SetCustomerSortFieldAC = (payload) => ({
  type: SET_CUSTOMER_SORT_FIELD,
  payload,
});

export const setCustomerPageAC: SetCustomerPageAC = (payload) => ({
  type: SET_CUSTOMER_PAGE,
  payload,
});

export const setCustomerSearchTextAC: SetCustomerSearchTextAC = (payload) => ({
  type: SET_CUSTOMER_SEARCH_TEXT,
  payload,
});
