import i18n from "i18next";

export interface BreadcrumbsItem {
  label: string;
  path: string;
  dustinIcon?: string;
  isDisabled?: boolean;
}

export interface BreadcrumbsConfigItem {
  label: string;
  crumbs?: BreadcrumbsItem[];
  dustinIcon?: string;
  match: { path: string; exact?: boolean };
}

export const getBreadcrumbsConfig = (): BreadcrumbsConfigItem[] => {
  const StartPage: BreadcrumbsItem = {
    label: i18n.t("breadcrumbs:Start"),
    path: "/start",
  };

  const Other: BreadcrumbsItem = {
    label: i18n.t("breadcrumbs:Other"),
    path: "/",
    isDisabled: true,
  };

  const breadcrumbsConfig: BreadcrumbsConfigItem[] = [
    {
      label: i18n.t("breadcrumbs:Agreements"),
      crumbs: [StartPage, Other],
      match: {
        path: "/agreements",
        exact: true,
      },
    },
  ];

  return breadcrumbsConfig;
};
