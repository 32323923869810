import React from "react";
import { Icon, IconProps, Text } from "@skyportal/ui-kit";

export interface OfferingTableRowProps {
  icon?: IconProps["icon"];
  label: string;
}

const OfferingTableRow: React.FC<OfferingTableRowProps> = ({ icon, label, children }) => {
  return (
    <tr>
      <td style={{ paddingRight: "8px" }}>{icon && <Icon icon={icon} />}</td>
      <td>
        <Text>{label}</Text>
      </td>
      <td>
        <Text>{children}</Text>
      </td>
    </tr>
  );
};

export default OfferingTableRow;
