import React from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import { Grid, Title, Icon, Text } from "@skyportal/ui-kit";
import { formatDate } from "utils/time";
import { AgreementModel } from "types/common.types";
import AgreementTableRow from "../AgreementTableRow";
import styles from "./styles.module.css";

export interface AgreementListItemProps {
  agreement: AgreementModel;
}

const AgreementListItem: React.FC<AgreementListItemProps> = ({ agreement }) => {
  const { t } = useTranslation("agreements");

  return (
    <li className={cn(styles.AgreementListItem)}>
      <Title level={3}>{agreement.name}</Title>
      <Grid container justify="flex-start" wrap="nowrap" spacing={1}>
        <Grid container item xs={6} direction="column">
          <table>
            <colgroup>
              <col width={20} />
            </colgroup>
            <tbody>
              {agreement.files.map((file, index) => (
                <tr key={index}>
                  <td style={{ paddingRight: "8px" }}>
                    <Icon icon="document-license" />
                  </td>
                  <a href={file.url} download target="_blank" rel="noreferrer">
                    <Text>{file.name}</Text>
                  </a>
                </tr>
              ))}
            </tbody>
          </table>
        </Grid>
        <Grid container item xs={6}>
          <table>
            <colgroup>
              <col width={20} />
              <col />
              <col />
            </colgroup>
            <tbody>
              <AgreementTableRow icon="calendar" label={t("Start Date")}>
                {formatDate(agreement.startDate)}
              </AgreementTableRow>
              {agreement.endDate && (
                <AgreementTableRow icon="calendar" label={t("End date")}>
                  {formatDate(agreement.endDate)}
                </AgreementTableRow>
              )}
              <AgreementTableRow icon="document-check" label={t("Signed By")}>
                {agreement.signedBy}
              </AgreementTableRow>
              {agreement.extensionPeriod && (
                <AgreementTableRow
                  icon="time-ongoing"
                  iconTooltip={agreement.autoExtend === "Yes" ? t("Automatically extended") : undefined}
                  label={t("Extension period (months)")}
                >
                  {agreement.extensionPeriod}
                </AgreementTableRow>
              )}
              {agreement.periodOfNotice && (
                <AgreementTableRow label={t("Notice period")}>{agreement.periodOfNotice}</AgreementTableRow>
              )}
            </tbody>
          </table>
        </Grid>
      </Grid>
    </li>
  );
};

export default AgreementListItem;
