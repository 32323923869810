// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".agreements_styles_pageLayout__Fw0O6 {\n  height: 100%;\n  padding: 0 1rem 1rem;\n  background-color: var(--color-grey-02);\n}\n", "",{"version":3,"sources":["webpack://./src/layouts/styles.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,oBAAoB;EACpB,sCAAsC;AACxC","sourcesContent":[".pageLayout {\n  height: 100%;\n  padding: 0 1rem 1rem;\n  background-color: var(--color-grey-02);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageLayout": "agreements_styles_pageLayout__Fw0O6"
};
export default ___CSS_LOADER_EXPORT___;
