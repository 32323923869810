import { redirectToAuth } from "@skyportal/auth-web-client/dist";
import { AppThunk } from "store";
import config from "config";
import { isUserAllowToShowAgreements } from "utils/users";
import { authClient } from "api/clients";
import * as appActions from "./app.actions";

export const getUserInfo = (): AppThunk => async (dispatch) => {
  try {
    const userProfile = await authClient.getUserProfile();

    if (isUserAllowToShowAgreements(userProfile)) {
      dispatch(appActions.setUserProfile(userProfile));
    } else {
      redirectToAuth(config.authWebUrl);
    }
  } catch (error) {
    console.error(error);
  }
};
