// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".agreements_styles_root__7quNJ {\n  margin: 0;\n  margin: initial;\n  color: inherit;\n  line-height: inherit;\n}\n\n.agreements_styles_tooltip__26Pii.SkyPortalTooltip.MuiTooltip-tooltip {\n  position: relative;\n  min-width: 100px;\n  padding: var(--padding-medium);\n  background-color: var(--color-white);\n  box-shadow: 0 10px 20px var(--color-grey-04);\n  color: inherit;\n}\n", "",{"version":3,"sources":["webpack://./src/containers/OfferingList/components/SlaTooltip/styles.module.css"],"names":[],"mappings":"AAAA;EACE,SAAa;EAAb,eAAa;EACb,cAAc;EACd,oBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,8BAA8B;EAC9B,oCAAoC;EACpC,4CAA4C;EAC5C,cAAc;AAChB","sourcesContent":[".root {\n  margin: unset;\n  color: inherit;\n  line-height: unset;\n}\n\n.tooltip:global(.SkyPortalTooltip.MuiTooltip-tooltip) {\n  position: relative;\n  min-width: 100px;\n  padding: var(--padding-medium);\n  background-color: var(--color-white);\n  box-shadow: 0 10px 20px var(--color-grey-04);\n  color: inherit;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "agreements_styles_root__7quNJ",
	"tooltip": "agreements_styles_tooltip__26Pii"
};
export default ___CSS_LOADER_EXPORT___;
