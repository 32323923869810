export enum RequestStatus {
  UNCALLED = "UNCALLED",
  PENDING = "PENDING",
  SUCCESS = "SUCCESS",
  FAILURE = "FAILURE",
}

export type OfferingType = "BaaS" | "STaaS" | "DRaaS" | "IaaS" | "NaaS" | "SaaS" | "DaaS" | string;
export type AgreementType = "ManagedServices" | "Consultancy" | string;
export type SLAType = string;
export type ApiDate = string;
export type FileContentType = "application/pdf" | string;
export type AgreementStatus = "Active" | "Expired";

export interface ContractFileModel {
  /**
   * Name/description of the file
   */
  name: string;
  /**
   * Type of file. Usually this would be application/pdf
   */
  contentType: FileContentType;
  /**
   * Link to the file
   * @example "https://some.sharepointsite.com/folder/contract_abc123.pdf"
   */
  url: string;
}

export interface AgreementModel {
  id: string;
  extensionPeriod: string;
  customerErpId: string;
  agreementNumber: string;
  name: string;
  signedBy: string;
  startDate: ApiDate;
  endDate: ApiDate;
  type: AgreementType;
  files: ContractFileModel[];
  periodOfNotice: string;
  autoExtend: "Yes" | "No";
}

export interface OfferingModel {
  id: string;
  name: string;
  customerErpId: string;
  customerPO: string;
  type: OfferingType;
  slaCode: SLAType;
  startDate: ApiDate;
  billStartDate: ApiDate;
  endDate: ApiDate | null;
  agreementStatus: AgreementStatus;
}

export interface SlaModel {
  id: string;
  slaCode: string;
  description: string;
}

export interface CustomerListItemModel {
  /**
   * Skyportal user id
   */
  _id: string;
  /**
   * Customer full name
   */
  name: string;
  /**
   * This is the Id that is currently stored in the Customer entity as AccountId - should be used as PartitionKey
   * @example "4700146608"
   */
  customerErpId: string;
}

export interface WithOffering {
  offering: OfferingModel;
}

export enum ModalKeysEnum {
  "CustomerSelect" = "CustomerSelect",
}
