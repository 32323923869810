import { RootState } from "../index";
import { customerListToSelectOptionList } from "utils/mappers";
import { createSelector } from "reselect";
import { getPaginationSlice, makeUniversalSort } from "utils/sort";
import { CustomerListItemModel } from "types/common.types";

export const getSlice = (state: RootState) => state.agreements;

export const getList = (state: RootState) => getSlice(state).list;
export const getOfferingsList = (state: RootState) => getSlice(state).offeringList;
export const getListRequest = (state: RootState) => getSlice(state).listRequest;
export const getOfferingListRequest = (state: RootState) => getSlice(state).offeringListRequest;
export const getCustomerErpId = (state: RootState) => getSlice(state).customerErpId;
export const getCustomerList = (state: RootState) => getSlice(state).customerList;
export const getCustomerListRequest = (state: RootState) => getSlice(state).customerListRequest;
export const getCustomersPage = (state: RootState) => getSlice(state).customersPage;
export const getCustomerSortOrder = (state: RootState) => getSlice(state).customerSortOrder;
export const getCustomerPerPage = (state: RootState) => getSlice(state).customersPerPage;
export const getCustomerSortField = (state: RootState) => getSlice(state).customersSortField;
export const getCustomerSearch = (state: RootState) => getSlice(state).searchCustomer;

export const getCustomerOptionsList = createSelector(getCustomerList, customerListToSelectOptionList);
export const getSelectedCustomer = createSelector(getCustomerList, getCustomerErpId, (list, customerErpId) =>
  list.find((list) => list.customerErpId === customerErpId)
);
const sortHandlers = {
  name: makeUniversalSort<CustomerListItemModel>((item) => item.name.toLowerCase()),
  customerErpId: makeUniversalSort<CustomerListItemModel>((item) => Number(item.customerErpId)),
} as const;

export const getCustomerSearchModified = createSelector(getCustomerSearch, (test) => test.toLowerCase());

export const getFilteredCustomersByTextSearch = createSelector(
  getCustomerList,
  getCustomerSearchModified,
  (list, searchString) =>
    searchString
      ? list.filter(
          (item) =>
            item.name.toLowerCase().includes(searchString) || item.customerErpId.toLowerCase().includes(searchString)
        )
      : list
);

export const getSortedCustomers = createSelector(
  getFilteredCustomersByTextSearch,
  getCustomerSortOrder,
  getCustomerSortField,
  (list, order, sortField) => (sortHandlers[sortField] ? [...list].sort(sortHandlers[sortField](order)) : list)
);

export const getPaginatedCustomerList = createSelector(
  getSortedCustomers,
  getCustomersPage,
  getCustomerPerPage,
  (list, page, perPage) => ({
    total: list.length,
    pageCount: Math.ceil(list.length / perPage),
    list: getPaginationSlice(list, page, perPage),
  })
);
