import { IconProps, SelectProps } from "@skyportal/ui-kit";
import { CustomerListItemModel, OfferingModel } from "types/common.types";

export const offeringTypeToIcon = (type: OfferingModel["type"]): IconProps["icon"] => {
  switch (type) {
    case "DRaaS":
      return "time-ongoing";
    case "BaaS":
      return "security-2";
    case "IaaS":
      return "cloud-safe";
    case "STaaS":
      return "server";
    case "NaaS":
      return "network-wifi-2";
    default:
      return "service";
  }
};

export const offeringTypeToLocaleLabel = (type: string): string => {
  switch (type) {
    case "DRaaS":
      return "Recovery as a Service";
    case "BaaS":
      return "Backup as a Service";
    case "IaaS":
      return "Infrastructure as a Service";
    case "SaaS":
      return "Software as a Service";
    case "STaaS":
      return "Storage as a Service";
    case "NaaS":
      return "Network as a Service";
    default:
      return type;
  }
};

export const offeringTypeToDashboardUrl = (type: OfferingModel["type"]): string => {
  switch (type) {
    case "DRaaS":
      return "/recovery";
    case "BaaS":
      return "/backup";
    case "IaaS":
      return "/infra";
    case "STaaS":
      return "/storage";
    case "NaaS":
      return "/naas";
    default:
      return "";
  }
};

export const customerListToSelectOptionList = (list: CustomerListItemModel[]): SelectProps["options"] =>
  list.map((item) => ({
    value: item.customerErpId,
    label: item.name,
  }));
