import { getUserProfileSkeleton } from "@skyportal/auth-web-client";
import { AppAction, AppStateSlice, SET_ACTIVE_MODAL, SET_USER_PROFILE } from "./app.types";
import { ModalKeysEnum } from "../../types/common.types";

const initState: AppStateSlice = {
  userProfile: getUserProfileSkeleton(),
  activeModal: null,
};

export default function AppReducer(state: AppStateSlice = initState, action: AppAction): AppStateSlice {
  switch (action.type) {
    case SET_USER_PROFILE: {
      return {
        ...state,
        userProfile: action.payload,
      };
    }
    case SET_ACTIVE_MODAL: {
      return {
        ...state,
        activeModal: action.payload,
      };
    }
    case "AGREEMENTS/SET_CUSTOMER_ERP_ID": {
      return {
        ...state,
        activeModal: state.activeModal === ModalKeysEnum.CustomerSelect ? null : state.activeModal,
      };
    }
    default:
      return state;
  }
}
