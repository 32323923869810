// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".agreements_styles_AgreementListItem__oa4y1 {\n  padding: var(--padding-medium);\n  border: 1px solid var(--color-grey-03);\n  border-radius: 2px;\n  transition: background-color var(--transition-duration);\n}\n\n.agreements_styles_AgreementListItem__oa4y1:hover {\n  background-color: var(--color-bg-hover);\n}\n\n.agreements_styles_AgreementListItem__oa4y1 a {\n  color: inherit;\n}\n\n.agreements_styles_AgreementListItem__oa4y1:not(:last-of-type) {\n  margin-bottom: var(--padding-small);\n}\n\n.agreements_styles_tooltipWrapper__f7hXz {\n  cursor: pointer;\n}\n", "",{"version":3,"sources":["webpack://./src/containers/AgreementList/components/AgreementListItem/styles.module.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAC9B,sCAAsC;EACtC,kBAAkB;EAClB,uDAAuD;AACzD;;AAEA;EACE,uCAAuC;AACzC;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,mCAAmC;AACrC;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".AgreementListItem {\n  padding: var(--padding-medium);\n  border: 1px solid var(--color-grey-03);\n  border-radius: 2px;\n  transition: background-color var(--transition-duration);\n}\n\n.AgreementListItem:hover {\n  background-color: var(--color-bg-hover);\n}\n\n.AgreementListItem a {\n  color: inherit;\n}\n\n.AgreementListItem:not(:last-of-type) {\n  margin-bottom: var(--padding-small);\n}\n\n.tooltipWrapper {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AgreementListItem": "agreements_styles_AgreementListItem__oa4y1",
	"tooltipWrapper": "agreements_styles_tooltipWrapper__f7hXz"
};
export default ___CSS_LOADER_EXPORT___;
