import React, { ChangeEvent, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { TextInput } from "@skyportal/ui-kit";
import { agreementsActions } from "store/agreements";
import { useAppDispatch } from "store";

const SearchInput = () => {
  const { t } = useTranslation("agreements");
  const dispatch = useAppDispatch();

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      dispatch(agreementsActions.setCustomerSearchTextAC(event.target.value));
    },
    [dispatch]
  );

  return <TextInput onChange={handleChange} placeholder={`${t("Search")}...`} />;
};

export default React.memo(SearchInput);
