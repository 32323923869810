import React from "react";
import { Grid, Icon } from "@skyportal/ui-kit";

export interface NotFoundPlaceholderLayoutProps {
  className?: string;
}

const NotFoundPlaceholderLayout: React.FC<NotFoundPlaceholderLayoutProps> = ({ className, children }) => {
  return (
    <Grid container justify="center" alignItems="center" spacing={1} className={className}>
      <Grid item style={{ display: "flex" }}>
        <Icon icon="search" />
      </Grid>
      <Grid item>{children}</Grid>
    </Grid>
  );
};

export default NotFoundPlaceholderLayout;
