import React from "react";
import { useTranslation } from "react-i18next";
import { Card, Grid } from "@skyportal/ui-kit";
import useCustomerAgreements from "hooks/useCustomerAgreements";
import AgreementListItem from "./components/AgreementListItem";
import NotFoundLabel from "./components/NotFoundLabel";
import styles from "./styles.module.css";

const AgreementList: React.FC = () => {
  const { t } = useTranslation("agreements");
  const { agreements, isFetching } = useCustomerAgreements();

  return (
    <Grid item xs={12} data-testid="agreementsListContainer">
      <Card padding="medium" loading={isFetching} className={styles.card} title={t("Agreement list")}>
        <NotFoundLabel show={!isFetching && !agreements.length} />
        <ul>
          {agreements.map((agreementItem) => (
            <AgreementListItem key={agreementItem.id} agreement={agreementItem} />
          ))}
        </ul>
      </Card>
    </Grid>
  );
};

export default React.memo(AgreementList);
