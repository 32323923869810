import { AppThunk } from "store";
import * as agreementsApi from "api/agreements.api";
import * as agreementsActions from "./agreements.actions";

export const fetchCustomerAgreements = (customerErpId: string): AppThunk => async (dispatch) => {
  dispatch(agreementsActions.agreementListRequest());
  try {
    const { data } = await agreementsApi.getCustomerAgreements(customerErpId);
    dispatch(agreementsActions.agreementListRequestSuccess(data.agreements));
  } catch (error) {
    dispatch(agreementsActions.agreementListRequestFailure());
  }
};

export const fetchCustomerList = (): AppThunk => async (dispatch) => {
  dispatch(agreementsActions.customerListRequestAC());
  try {
    const { data } = await agreementsApi.getCustomerList();
    dispatch(agreementsActions.customerListRequestSuccessAC(data));
  } catch (error) {
    dispatch(agreementsActions.customerListRequestFailureAC());
  }
};

export const fetchCustomerOfferingList = (customerErpId: string): AppThunk => async (dispatch) => {
  dispatch(agreementsActions.offeringListRequestAC());
  try {
    const { data } = await agreementsApi.getCustomerOfferings(customerErpId);
    dispatch(agreementsActions.offeringListRequestSuccessAC(data.offerings));
  } catch (error) {
    dispatch(agreementsActions.offeringListRequestFailureAC());
  }
};
