import { combineReducers } from "redux";
import { reducer as app } from "./app";
import { reducer as agreements } from "./agreements";

const rootReducer = combineReducers({
  app,
  agreements,
});

export default rootReducer;
