import { SET_ACTIVE_MODAL, SET_USER_PROFILE, SetActiveModalAC, SetUserProfileAC } from "./app.types";

export const setUserProfile: SetUserProfileAC = (payload) => ({
  type: SET_USER_PROFILE,
  payload,
});

export const setActiveModalAC: SetActiveModalAC = (payload) => ({
  type: SET_ACTIVE_MODAL,
  payload,
});
