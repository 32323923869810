import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import { Button, Tooltip, Spinner } from "@skyportal/ui-kit";
import { WithOffering } from "types/common.types";
import { isSlaInvalid } from "utils/sla";
import useSlaFetch from "hooks/useSlaFetch";
import styles from "./styles.module.css";

export interface SlaSummaryProps extends WithOffering {}

const SlaTooltip: React.FC<SlaSummaryProps> = ({ offering }) => {
  const [open, setOpen] = useState(false);
  const { data, isPending, fetch } = useSlaFetch();

  useEffect(() => {
    if (offering.slaCode && (!data || data.slaCode !== offering.slaCode) && !isPending && open) {
      fetch(offering.slaCode);
    }
  }, [fetch, data, isPending, open, offering]);

  if (isSlaInvalid(offering.slaCode)) {
    return null;
  }

  return (
    <div className={styles.root}>
      <Tooltip
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        arrow={false}
        open={open}
        classes={{ tooltip: styles.tooltip }}
        title={
          !data || isPending ? (
            <Spinner show size={12} />
          ) : (
            <ReactMarkdown remarkPlugins={[gfm]}>{data.description}</ReactMarkdown>
          )
        }
      >
        <span>
          <Button type="tertiary">{offering.slaCode}</Button>
        </span>
      </Tooltip>
    </div>
  );
};

export default React.memo(SlaTooltip);
