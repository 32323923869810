import React, { useCallback } from "react";
import { Button, Grid, Modal } from "@skyportal/ui-kit";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ModalKeysEnum } from "types/common.types";
import { appActions, appSelectors } from "store/app";
import { useAppDispatch } from "store";
import SearchInput from "./components/SerachInput";
import TotalSearchResult from "./components/TotalSearchResult";
import CustomerSelectionModalTable from "./components/Table";
import CustomerSelectionModalPagination from "./components/Pagination";
import styles from "./styles.module.css";

const CustomerSelectionModal: React.FC = () => {
  const { t } = useTranslation("agreements");
  const dispatch = useAppDispatch();
  const isOpen = useSelector(appSelectors.getActiveModal) === ModalKeysEnum.CustomerSelect;

  const handleAbortClick = useCallback(() => {
    dispatch(appActions.setActiveModalAC(null));
  }, [dispatch]);

  return (
    <Modal
      open={isOpen}
      title={t("Select Customer")}
      onClose={handleAbortClick}
      controls={
        <Button type="secondary" onClick={handleAbortClick}>
          {t("common:Cancel")}
        </Button>
      }
    >
      <div data-testid="CustomerSelectionModal">
        <Grid container alignItems="center" justify="space-between" className={styles.searchBlock}>
          <Grid item>
            <TotalSearchResult />
          </Grid>
          <Grid item>
            <SearchInput />
          </Grid>
        </Grid>
        <Grid container className={styles.root}>
          <Grid item xs={12}>
            <CustomerSelectionModalTable />
          </Grid>
        </Grid>
        <Grid container justify="flex-end" className={styles.block}>
          <Grid item>
            <CustomerSelectionModalPagination />
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
};

export default CustomerSelectionModal;
