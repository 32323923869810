// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".agreements_styles_root__Bxms6 {\n  min-height: 150px;\n  max-height: 400px;\n  overflow-y: auto;\n}\n\n.agreements_styles_block__OKY8k {\n  margin-top: var(--padding-medium);\n}\n\n.agreements_styles_searchBlock__etaEq {\n  margin-bottom: var(--padding-medium);\n}\n", "",{"version":3,"sources":["webpack://./src/modals/CustomerSelectionModal/styles.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,iCAAiC;AACnC;;AAEA;EACE,oCAAoC;AACtC","sourcesContent":[".root {\n  min-height: 150px;\n  max-height: 400px;\n  overflow-y: auto;\n}\n\n.block {\n  margin-top: var(--padding-medium);\n}\n\n.searchBlock {\n  margin-bottom: var(--padding-medium);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "agreements_styles_root__Bxms6",
	"block": "agreements_styles_block__OKY8k",
	"searchBlock": "agreements_styles_searchBlock__etaEq"
};
export default ___CSS_LOADER_EXPORT___;
