import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import RootPage from "pages/RootPage";

export const AppRouter: React.FC = () => {
  return (
    <Switch>
      <Route path="/agreements" exact component={RootPage} />
      <Route path="*">
        <Redirect to="/agreements" />
      </Route>
    </Switch>
  );
};

export default AppRouter;
