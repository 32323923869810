import React from "react";
import { Icon, IconProps, Text, Tooltip } from "@skyportal/ui-kit";
import styles from "./styles.module.css";

export interface AgreementTableRowProps {
  icon?: IconProps["icon"];
  iconTooltip?: string;
  label: string;
}

const AgreementTableRow: React.FC<AgreementTableRowProps> = ({ icon, iconTooltip, label, children }) => {
  return (
    <tr>
      <td style={{ paddingRight: "8px" }}>
        {icon &&
          (iconTooltip ? (
            <Tooltip title={iconTooltip}>
              <span className={styles.tooltipWrapper}>
                <Icon icon={icon} />
              </span>
            </Tooltip>
          ) : (
            <Icon icon={icon} />
          ))}
      </td>
      <td>
        <Text>{label}</Text>
      </td>
      <td>
        <Text>{children}</Text>
      </td>
    </tr>
  );
};

export default AgreementTableRow;
