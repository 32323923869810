import { AgreementModel, CustomerListItemModel, RequestStatus, OfferingModel } from "types/common.types";
import { SetActiveModalAction } from "../app/app.types";

export type CustomerSortFiled = Exclude<keyof CustomerListItemModel, "_id">;

export interface AgreementsStateSlice {
  readonly customerErpId: string;
  readonly customerList: CustomerListItemModel[];
  readonly customerListRequest: RequestStatus;
  readonly list: AgreementModel[];
  readonly listRequest: RequestStatus;
  readonly offeringList: OfferingModel[];
  readonly offeringListRequest: RequestStatus;
  readonly customersPerPage: number;
  readonly customersPage: number;
  readonly searchCustomer: string;
  readonly customerSortOrder: 1 | -1;
  readonly customersSortField: CustomerSortFiled;
}

export const SET_CUSTOMER_SEARCH_TEXT = "AGREEMENTS/SET_CUSTOMER_SEARCH_TEXT";
export type SetCustomerSearchTextAction = {
  type: typeof SET_CUSTOMER_SEARCH_TEXT;
  payload: AgreementsStateSlice["searchCustomer"];
};
export type SetCustomerSearchTextAC = (payload: AgreementsStateSlice["searchCustomer"]) => SetCustomerSearchTextAction;

export const SET_CUSTOMER_PAGE = "AGREEMENTS/SET_CUSTOMER_PAGE";
export type SetCustomerPageAction = {
  type: typeof SET_CUSTOMER_PAGE;
  payload: AgreementsStateSlice["customersPage"];
};
export type SetCustomerPageAC = (payload: AgreementsStateSlice["customersPage"]) => SetCustomerPageAction;

export const SET_CUSTOMER_SORT_FIELD = "AGREEMENTS/SET_CUSTOMER_SORT_FIELD";
export type SetCustomerSortFieldAction = {
  type: typeof SET_CUSTOMER_SORT_FIELD;
  payload: AgreementsStateSlice["customersSortField"];
};
export type SetCustomerSortFieldAC = (
  payload: AgreementsStateSlice["customersSortField"]
) => SetCustomerSortFieldAction;

export const SET_CUSTOMER_ERP_ID = "AGREEMENTS/SET_CUSTOMER_ERP_ID";
export type SetCustomerErpIdAction = {
  type: typeof SET_CUSTOMER_ERP_ID;
  payload: string;
};
export type SetCustomerErpIdAC = (customerErpId: string) => SetCustomerErpIdAction;

export const LIST_REQUEST = "AGREEMENTS/LIST_REQUEST";
export type AgreementListRequestAction = {
  type: typeof LIST_REQUEST;
};
export type AgreementListRequestAC = () => AgreementListRequestAction;

export const LIST_REQUEST_SUCCESS = "AGREEMENTS/LIST_REQUEST_SUCCESS";
export type AgreementListRequestSuccessAction = {
  type: typeof LIST_REQUEST_SUCCESS;
  payload: AgreementModel[];
};
export type AgreementListRequestSuccessAC = (agreements: AgreementModel[]) => AgreementListRequestSuccessAction;

export const LIST_REQUEST_FAILURE = "AGREEMENTS/LIST_REQUEST_FAILURE";
export type AgreementListRequestFailureAction = {
  type: typeof LIST_REQUEST_FAILURE;
};
export type AgreementListRequestFailureAC = () => AgreementListRequestFailureAction;

export const CUSTOMER_LIST_REQUEST = "AGREEMENTS/CUSTOMER_LIST_REQUEST";
export type CustomerListRequestAction = {
  type: typeof CUSTOMER_LIST_REQUEST;
};
export type CustomerListRequestAC = () => CustomerListRequestAction;

export const CUSTOMER_LIST_REQUEST_SUCCESS = "AGREEMENTS/CUSTOMER_LIST_REQUEST_SUCCESS";
export type CustomerListRequestSuccessAction = {
  type: typeof CUSTOMER_LIST_REQUEST_SUCCESS;
  payload: CustomerListItemModel[];
};
export type CustomerListRequestSuccessAC = (customerList: CustomerListItemModel[]) => CustomerListRequestSuccessAction;

export const CUSTOMER_LIST_REQUEST_FAILURE = "AGREEMENTS/CUSTOMER_LIST_REQUEST_FAILURE";
export type CustomerListRequestFailureAction = {
  type: typeof CUSTOMER_LIST_REQUEST_FAILURE;
};
export type CustomerListRequestFailureAC = () => CustomerListRequestFailureAction;

export const OFFERING_LIST_REQUEST = "AGREEMENTS/OFFERING_LIST_REQUEST";
export type OfferingListRequestAction = {
  type: typeof OFFERING_LIST_REQUEST;
};
export type OfferingListRequestAC = () => OfferingListRequestAction;

export const OFFERING_LIST_REQUEST_SUCCESS = "AGREEMENTS/OFFERING_LIST_REQUEST_SUCCESS";
export type OfferingListRequestSuccessAction = {
  type: typeof OFFERING_LIST_REQUEST_SUCCESS;
  payload: OfferingModel[];
};
export type OfferingListRequestSuccessAC = (offeringList: OfferingModel[]) => OfferingListRequestSuccessAction;

export const OFFERING_LIST_REQUEST_FAILURE = "AGREEMENTS/OFFERING_LIST_REQUEST_FAILURE";
export type OfferingListRequestFailureAction = {
  type: typeof OFFERING_LIST_REQUEST_FAILURE;
};
export type OfferingListRequestFailureAC = () => OfferingListRequestFailureAction;

export type AgreementActions =
  | OfferingListRequestAction
  | OfferingListRequestSuccessAction
  | OfferingListRequestFailureAction
  | AgreementListRequestAction
  | SetCustomerErpIdAction
  | AgreementListRequestSuccessAction
  | AgreementListRequestFailureAction
  | CustomerListRequestAction
  | CustomerListRequestSuccessAction
  | CustomerListRequestFailureAction
  | SetCustomerSearchTextAction
  | SetCustomerPageAction
  | SetCustomerSortFieldAction
  | SetActiveModalAction;
