export function makeUniversalSort<T>(normalizer: (value: T) => any) {
  return function (sortWay: -1 | 1) {
    return function (a: T, b: T) {
      try {
        return normalizer(a) >= normalizer(b) ? sortWay * -1 : sortWay;
      } catch (error) {
        return 0;
      }
    };
  };
}

export function getPaginationSlice<T>(list: T[], page: number, perPage: number) {
  return list.slice((page - 1) * perPage, page * perPage);
}
