// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".agreements_styles_tooltipWrapper__r0I35 {\n  cursor: pointer;\n}\n", "",{"version":3,"sources":["webpack://./src/containers/AgreementList/components/AgreementTableRow/styles.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB","sourcesContent":[".tooltipWrapper {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltipWrapper": "agreements_styles_tooltipWrapper__r0I35"
};
export default ___CSS_LOADER_EXPORT___;
