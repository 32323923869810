import React from "react";
import CustomerSelectionModal from "./CustomerSelectionModal";

const ModalContainer = () => (
  <>
    <CustomerSelectionModal />
  </>
);

export default ModalContainer;
