import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useThunkDispatch } from "store";
import { agreementsSelectors, agreementsThunks } from "store/agreements";
import { OfferingModel, RequestStatus } from "types/common.types";

export interface UseCustomerOfferings {
  offerings: OfferingModel[];
  isFetching: boolean;
}

const useCustomerOfferings = () => {
  const dispatch = useThunkDispatch();
  const isFetching = useSelector(agreementsSelectors.getOfferingListRequest) === RequestStatus.PENDING;
  const offerings = useSelector(agreementsSelectors.getOfferingsList);
  const customerErpId = useSelector(agreementsSelectors.getCustomerErpId);

  useEffect(() => {
    if (customerErpId) {
      dispatch(agreementsThunks.fetchCustomerOfferingList(customerErpId));
    }
  }, [dispatch, customerErpId]);

  return { offerings, isFetching };
};

export default useCustomerOfferings;
