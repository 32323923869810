import { useThunkDispatch } from "../store";
import { useSelector } from "react-redux";
import { agreementsSelectors, agreementsThunks } from "../store/agreements";
import { RequestStatus } from "../types/common.types";
import { useEffect } from "react";
import { appSelectors } from "../store/app";

interface UseCustomerList {
  isFetching: boolean;
}

const useCustomerList = (): UseCustomerList => {
  const dispatch = useThunkDispatch();
  const userProfile = useSelector(appSelectors.getUserProfile);
  const request = useSelector(agreementsSelectors.getCustomerListRequest);

  useEffect(() => {
    if (userProfile.is_admin) {
      dispatch(agreementsThunks.fetchCustomerList());
    }
  }, [dispatch, userProfile]);

  return { isFetching: request === RequestStatus.PENDING };
};
export default useCustomerList;
