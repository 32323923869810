import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import { Pagination } from "@skyportal/ui-kit";
import { useAppDispatch } from "store";
import { agreementsActions, agreementsSelectors } from "store/agreements";

const CustomerSelectionModalPagination = () => {
  const dispatch = useAppDispatch();
  const { pageCount } = useSelector(agreementsSelectors.getPaginatedCustomerList);
  const page = useSelector(agreementsSelectors.getCustomersPage);

  const handleChange = useCallback(
    (page: number) => {
      dispatch(agreementsActions.setCustomerPageAC(page));
    },
    [dispatch]
  );

  return <Pagination size="small" page={page} count={pageCount} onChange={handleChange} />;
};

export default React.memo(CustomerSelectionModalPagination);
