import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Title, Button, Card, Grid } from "@skyportal/ui-kit";
import { useSelector } from "react-redux";
import { appActions, appSelectors } from "store/app";
import { agreementsSelectors } from "store/agreements";
import { useAppDispatch } from "store";
import useCustomerList from "hooks/useCustomerList";
import { ModalKeysEnum } from "types/common.types";

const AdminFilterBar: React.FC = () => {
  const { t } = useTranslation("agreements");
  const { isFetching } = useCustomerList();
  const user = useSelector(appSelectors.getUserProfile);
  const selectedCustomer = useSelector(agreementsSelectors.getSelectedCustomer);
  const dispatch = useAppDispatch();

  const handleButtonClick = useCallback(() => {
    dispatch(appActions.setActiveModalAC(ModalKeysEnum.CustomerSelect));
  }, [dispatch]);

  if (!user.is_admin) {
    return null;
  }

  return (
    <Grid item xs={12} data-testid="AdminFilterBar">
      <Card padding="medium" loading={isFetching}>
        <Grid container spacing={1} justify="space-between">
          <Grid item>
            <Title level={4}>{selectedCustomer ? selectedCustomer.name : t("No Customer selected")}</Title>
          </Grid>
          <Grid item>
            <Button onClick={handleButtonClick}>
              {selectedCustomer ? t("Select another Customer") : t("Select Customer")}
            </Button>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
};

export default AdminFilterBar;
