import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Button, ColumnConfig, Table } from "@skyportal/ui-kit";
import { useSelector } from "react-redux";
import { useAppDispatch } from "store";
import { agreementsActions, agreementsSelectors } from "store/agreements";
import { CustomerListItemModel } from "types/common.types";

const CustomerSelectionModalTable = () => {
  const { t } = useTranslation("agreements");
  const dispatch = useAppDispatch();
  const { list } = useSelector(agreementsSelectors.getPaginatedCustomerList);
  const customerErpId = useSelector(agreementsSelectors.getCustomerErpId);
  const sortField = useSelector(agreementsSelectors.getCustomerSortField);
  const sortOrder = useSelector(agreementsSelectors.getCustomerSortOrder);

  const handleSortFieldChange = (sortField: string) => {
    dispatch(agreementsActions.setCustomerSortFieldAC(sortField as any));
  };

  const columns: ColumnConfig<CustomerListItemModel>[] = useMemo(
    () => [
      { title: t("Name"), key: "name", dataIndex: "name" },
      { title: t("Id"), dataIndex: "customerErpId", key: "customerErpId", width: "80px" },
      {
        title: "",
        dataIndex: "",
        key: "options",
        sortDisabled: true,
        width: "80px",
        align: "right",
        render: (value, record) => (
          <Button
            disabled={customerErpId === record.customerErpId}
            size="small"
            onClick={() => {
              if (customerErpId === record.customerErpId) {
                return;
              }
              dispatch(agreementsActions.setCustomerErpIdAC(record.customerErpId));
            }}
          >
            {customerErpId !== record.customerErpId ? t("Select") : t("Selected")}
          </Button>
        ),
      },
    ],
    [customerErpId, t, dispatch]
  );

  return (
    <Table
      rowKey="_id"
      sortField={sortField}
      sortOrder={sortOrder === -1 ? "desc" : "asc"}
      size="small"
      columns={columns}
      dataSource={list}
      onSortFieldChange={handleSortFieldChange}
    />
  );
};

export default React.memo(CustomerSelectionModalTable);
