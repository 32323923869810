import { UserProfile } from "@skyportal/auth-web-client";
import { ModalKeysEnum } from "types/common.types";
import { SetCustomerErpIdAction } from "../agreements/agreements.types";

export interface AppStateSlice {
  readonly userProfile: UserProfile;
  readonly activeModal: ModalKeysEnum | null;
}
export const SET_USER_PROFILE = "APP/SET_USER_PROFILE";
export type SetUserProfileAction = {
  type: typeof SET_USER_PROFILE;
  payload: UserProfile;
};
export type SetUserProfileAC = (userProfile: UserProfile) => SetUserProfileAction;

export const SET_ACTIVE_MODAL = "APP/SET_ACTIVE_MODAL";
export type SetActiveModalAction = {
  type: typeof SET_ACTIVE_MODAL;
  payload: AppStateSlice["activeModal"];
};
export type SetActiveModalAC = (payload: AppStateSlice["activeModal"]) => SetActiveModalAction;

export type AppAction = SetUserProfileAction | SetActiveModalAction | SetCustomerErpIdAction;
